import React, { Component } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Navbar from 'react-bootstrap/navbar'
import Nav from 'react-bootstrap/nav'
import Row from 'react-bootstrap/Row';
import {Link, useHistory} from "react-router-dom";

// Styling
import 'bootstrap/dist/css/bootstrap.min.css';
import './HeaderFooter.scss'


// The navigation bar
class Footer extends Component {

  constructor(props) {
      super(props);
      this.state = { content: "Initialize" }

    }

  render() {
  return (
    <div>
    <footer className="footer" style={{"display" : "flex",  "width" : "100%"}}>
      <div style={{"width" : "100%"}}>
      <div className="legal">Pokémon is © of Nintendo, 1995 - 2021</div>
      <div className="legal">A webapp by <a href="https://twitter.com/Ruxaroh">Ruxaroh</a> || This app would not of been possible without <a href="https://bulbapedia.bulbagarden.net"> bulbapedia </a> ❤️</div>
      </div>
      <div>
        <a href="https://www.patreon.com/ruxaroh"><img className="patreonLink" src="/patreon-icon.png" ></img></a>
      </div>
      </footer>

  <CookieConsent
  location="bottom"
  buttonText="Allow all cookies"
  declineButtonText="Disable Analytic Cookies"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#2B373B" }}
  declineButtonStyle={{ color: "white", fontSize: "13px"}}
  buttonStyle={{ color: "white", fontSize: "13px", background:"green" }}
  flipButtons={true}
  expires={150}
  enableDeclineButton
  onAccept={() => { localStorage.analyticCookieConsent = true; window['ga-disable-G-G25DW1E5WB'] = false
  }}
>
  This website has some mandatory cookies to function correctly. We also store some cookies for analytic reasons, you can disable those if you want.
</CookieConsent>
      </div>
  );
}
}

export default Footer;