import React, { Component } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import { BrowserView, MobileView } from 'react-device-detect';
import * as shape from 'react-shapes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSchool, faMapMarkerAlt, faChalkboardTeacher, faCoins, faLaptop } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory, Route } from "react-router-dom";

import * as common from "../control/common";
import "./TeamPlanner.scss";

// Page Components
import DrawTeamSelection from './selectedTeam';
import DrawPokemonOptions from "./teamOptions";
import DrawFilters from "./filterOptions";
import { red } from "@material-ui/core/colors";

const types = [
  "bug",
  "dark",
  "dragon",
  "electric",
  "fairy",
  "fighting",
  "fire",
  "flying",
  "ghost",
  "grass",
  "ground",
  "ice",
  "normal",
  "poison",
  "psychic",
  "rock",
  "steel",
  "water",
];

class TeamPlanner extends Component {

  constructor(props) {
    super(props);

    this.state = {
      teamID: 1,
      imageLoadCount: 0,
      dataLoaded: false,
      isLoaded: false,
      game: red,
      gen: 1,
      teamData: [],
      gameData: {
      },
      filters: {
        evolved: false,
        versionExclusive: true,
        legendary: false,
        dupeType: false,
        filterType: [],
      }
    }
  }

  updateFilter = (type) => {
    if (type == "evolved") {
      this.state.filters.evolved = !this.state.filters.evolved;
    }
    if (type == "versionExclusive") {
      this.state.filters.versionExclusive = !this.state.filters.versionExclusive;
    }
    if (type == "legendary") {
      this.state.filters.legendary = !this.state.filters.legendary;
    }
    if (type == "dupeType") {
      this.state.filters.dupeType = !this.state.filters.dupeType;
    }

    this.setState({});
  }

  typeToggle = () => {
    for (var type = 0; type < types.length; type++) {
      if (!this.state.filters.filterType.includes(types[type])) {
        this.state.filters.filterType.push(types[type])
      } else {
        for (var i = 0; i < this.state.filters.filterType.length; i++) {
          if (this.state.filters.filterType[i] == types[type]) {
            this.state.filters.filterType.splice(i, 1);
          }
        }
      }
    }
    this.setState({});
  }

  updateTypeFilter = (type) => {
    if (!this.state.filters.filterType.includes(type)) {
      this.state.filters.filterType.push(type)
    } else {
      for (var i = 0; i < this.state.filters.filterType.length; i++) {
        if (this.state.filters.filterType[i] == type) {
          this.state.filters.filterType.splice(i, 1);
        }
      }
    }

    this.setState({});
  }

  saveTeam = () => {
    if (typeof (Storage) !== "undefined") {
      var data = JSON.parse(localStorage.data)
      var found = false
      var newTeamMembers = []

      for (var member = 0; member < this.state.teamData.length; member++) {
        newTeamMembers.push([this.state.teamData[member].name.toLowerCase(), this.state.teamData[member].form])
      }
      for (var team = 0; team < data.teams.length; team++) {
        if (data.teams[team].teamID == this.state.teamID) {
          found = true
          data.teams[team] = {
            teamID: this.state.teamID,
            gen: this.state.gen,
            game: this.state.game,
            team: newTeamMembers
          };
        }
        if (found) { break }
      }
      localStorage.data = JSON.stringify(data)
      return
    } else {
      return
    }
  }

  addMember = (name, form) => {
    var newTeam = this.state.teamData

    var found = false;
    if (this.state.teamData.length <= 5) {
      for (const [section, dex] of Object.entries(this.state.gameData.dexs)) {
        for (var entry = 0; entry < dex.length; entry++) {
          if (name == dex[entry].name && form == dex[entry].form) {
            this.state.teamData.push(dex[entry]);
            found = true;
            break;
          }
          if (found) { break; }
        }
      }
      this.saveTeam()
      this.setState({});
    }
  }

  removeMember = (name, form) => {
    for (var i = 0; i < this.state.teamData.length; i++) {
      if (this.state.teamData[i].name == name && this.state.teamData[i].form == form) {
        this.state.teamData.splice(i, 1);
      }
      this.saveTeam()
      this.setState({});
    }
  }


  componentDidMount() {
    fetch(`${process.env.PUBLIC_URL}/pokemonData/${this.props.generation}/dex-${this.props.game}.json`).then(res => {
      return (res.json());
    }).then(json => {

      var team = this.props.team
      var teamData = [];
      var found = false;
      
      for (var member = 0; member < team.length; member++) {
        found = false;
        if (team[member][1] == null) {
          team[member][1] = 0
        }
        for (const [section, dex] of Object.entries(json.dexs)) {
          for (var entry = 0; entry < dex.length; entry++) {
            if (team[member][0] == dex[entry].name.toLowerCase() && team[member][1] == dex[entry].form) {
              teamData.push(dex[entry])
              found = true;
              break;
            }
            if (found) { break; }
          }
        }
      }

      this.setState({
        teamID: this.props.teamID,
        game: this.props.game,
        gen: this.props.generation,
        teamData: [...teamData],
        isLoaded: true,
        gameData: json,
      });
    }).catch((error) => {
      this.setState({
        isLoaded: true,
        fetchError: error
      });
    });

  }

  render() {
    if (this.state.isLoaded === true && (!this.state.fetchError)) {
      // Set page title to the current course name
      document.title = "Pokémon " + this.state.gameData.gameName + " Team Planner";
      return (
        <div className="teamPlanner" >
          <h1 className="titleText">
            Pokémon {this.state.gameData.gameName}
          </h1>
          <DrawTeamSelection teamData={this.state.teamData} album={this.state.gameData.album} removeMember={this.removeMember} />
          <DrawFilters status={this.state.filters} updateFilter={this.updateFilter} updateTypeFilter={this.updateTypeFilter} typeToggle={this.typeToggle} />
          <DrawPokemonOptions dex={this.state.gameData.dexs} teamData={this.state.teamData} filters={this.state.filters} addMember={this.addMember} />
        </div>
      );
    } else if (this.state.fetchError) {
      return (<common.ErrorPage error={this.state.fetchError.message} />)
    } else {
      return (<div>Loading...</div>)
    }
  }
}

export default TeamPlanner;
