import React, { Component } from "react";

import * as common from "./common";
import Home from "./home"
import TeamPlanner from "../planner/TeamPlanner"

class WelcomePage extends Component {
  render() {
    localStorage.lastVersion = "0.3.0"
    return (
      <div className="welcomePage">
        <h1>Version 0.3.0 - What's New?</h1>
        <p>
          National dex options, better navigation and Let's GO! It's the update quite literally everyone has been asking for. 
          <ul>
            <li>Fixed Larvitar and Pupitar showing as fully evolved.</li>
            <li>Fixed the Clefable line not having types in Pokémon Balck 2 and White 2.</li>
            <li>Fixed the Calyrex rider forms have the inverse typings.</li>
          </ul>
        </p>
        <hr></hr>
        <h1>Patreon...</h1>
        <p style={{ "textAlign": "center" }}>If you like my site, please consider supporting me on patreon!
          Hosting is expensive and your support will directly help keep the app ad free!
        </p><p style={{ "textAlign": "center" }}>For pledging you will get access to some exclusive content including dev logs, previews of future features and the chance to vote on what comes next.
          <a href="https://www.patreon.com/ruxaroh"> <div className="welcomeHomeButton"> <img src="/patreon-icon.png" />
          </div>
          </a>
        </p >
        <div style={{ "height": "50px" }} />
        <hr></hr>
        <h1>Get me out of here!</h1>
        <p>To get to the app you can refresh or hit the home button!</p>
            <a href="/">
        <div className="welcomeHome">
          <div className="welcomeHomeButton">
            <img title="Home" src="/home-icon.png" />
          </div>
        </div>
            </a>
      </div >
    )
  }
}

class Main extends Component {

  constructor(props) {
    super(props)
    this.state = {
      atHome: true,
      game: "red",
      generation: "1",
      teamID: "0",
      team: []
    }
  }

  loadTeamPlanner = (gen, game, team, id) => {

    if (id == 0) {
      if (typeof (Storage) !== "undefined") {
        var data = JSON.parse(localStorage.data)
        if (data.teams.length == 0) {
          var id = 1
        } else {
          var id = data.teams.at(-1).teamID + 1
        }
        data.teams.push({ teamID: id, gen: gen, game: game, team: [] })
        localStorage.data = JSON.stringify(data)
      } else {
        id = 1
      }
    }

    this.state.atHome = false;
    this.state.game = game;
    this.state.generation = String(gen);
    this.state.team = team;
    this.state.teamID = id;
    this.setState({});
  }

  render() {
    window['ga-disable-G-G25DW1E5WB'] = true
    var savedVersion = "0.0.0"
    savedVersion = localStorage.lastVersion
    if (typeof (Storage) !== "undefined") {
      if (localStorage.data == null) {
        localStorage.data = '{"darkMode": 0, "teams": []}'
        if (localStorage.analyticCookieConsent == true) {
          window['ga-disable-G-G25DW1E5WB'] = false
        }
      }
    }
    console.log("Saved Version:" + savedVersion)
    return(
      <div style={{"margin-left": "1%"}}>
        <h1>
          Generations Team Planner Will be Back!
        </h1>
        <p>
          This site is a massive passion project for me but at present I do not have the time or the needed resources to maintain it.
          <div/>
          In a mix of laziness and cost cutting I used <a href="https://pokemondb.net/">https://pokemondb.net/</a> to supply my sprites. This was not the correct action and only ended up negatively impacting the incredible people over there.
          <div />
          Once I am in a place where I can properly support this site both financially and with the time I need to update it I will return.
          <div style={{"margin-top":"10px"}}/>
          Thank you all for your great support up until now.
          - Ruxaroh 
        </p>
      </div>
    );
    /*
    if (savedVersion != "0.3.0") {
      return (
        <div>
        <WelcomePage />
        </div>
        )
      }
      else if (this.state.atHome) {
      return (
        <div>
        <Home loadTeamPlanner={this.loadTeamPlanner} />
        </div>
        );
      } else {
        return (
          <TeamPlanner generation={this.state.generation} game={this.state.game} team={this.state.team} teamID={this.state.teamID} />
          );
        }
        */
      }

}

export default Main;
