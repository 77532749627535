import React, { Component } from "react";
import ScrollContainer from 'react-indiana-drag-scroll';
import Navbar from 'react-bootstrap/navbar'
import { BrowserView, MobileView } from 'react-device-detect';
import * as common from "./common";

class GameLink extends Component {
  render() {
    var source = "/gameIcons/cover-" + this.props.game + ".png";
    return (
      <div className="gameCoverImageContainer" onClick={() => this.props.loadTeamPlanner(this.props.gen, this.props.game, [], 0)}>
        <img className="gameCoverImage" src={source} />
      </div>
    );
  }
}

class GameList extends Component {
  render() {
    if (this.props.browser) {
      var selectorStyle = "gameSelectorBrowser"
    } else {
      var selectorStyle = "gameSelectorMobile"
    }
    return (
          <div className={selectorStyle}>
            <div className="gen_box">
              <div className="generationText"> I </div>
              <div className="generationGames">
                <GameLink game={"red"} gen={1} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"blue"} gen={1} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"yellow"} gen={1} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>

            <div className="gen_box">
              <div className="generationText"> II </div>
              <div className="generationGames">
                <GameLink game={"gold"} gen={2} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"silver"} gen={2} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"crystal"} gen={2} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>


            <div className="gen_box">
              <div className="generationText"> III </div>
              <div className="generationGames">
                <GameLink game={"ruby"} gen={3} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"sapphire"} gen={3} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"emerald"} gen={3} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"firered"} gen={3} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"leafgreen"} gen={3} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>


            <div className="gen_box">
              <div className="generationText"> IV </div>
              <div className="generationGames">
                <GameLink game={"diamond"} gen={4} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"pearl"} gen={4} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"platinum"} gen={4} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"heartgold"} gen={4} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"soulsilver"} gen={4} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>


            <div className="gen_box">
              <div className="generationText"> V </div>
              <div className="generationGames">
                <GameLink game={"black"} gen={5} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"white"} gen={5} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"blacktwo"} gen={5} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"whitetwo"} gen={5} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>


            <div className="gen_box">
              <div className="generationText"> VI </div>
              <div className="generationGames">
                <GameLink game={"x"} gen={6} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"y"} gen={6} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"omegaruby"} gen={6} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"alphasapphire"} gen={6} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>


            <div className="gen_box">
              <div className="generationText"> VII </div>
              <div className="generationGames">
                <GameLink game={"sun"} gen={7} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"moon"} gen={7} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"ultrasun"} gen={7} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"ultramoon"} gen={7} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>


            <div className="gen_box">
              <div className="generationText"> VIII </div>
              <div className="generationGames">
                <GameLink game={"sword"} gen={8} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"shield"} gen={8} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"brilliantdiamond"} gen={8} loadTeamPlanner={this.props.loadTeamPlanner} />
                <GameLink game={"shiningpearl"} gen={8} loadTeamPlanner={this.props.loadTeamPlanner} />
              </div>
            </div>
          </div>
    );
  }
}

class SavedTeamMember extends Component {
  render() {
    if (this.props.member != null) {
      return (<img className="teamMemberImage" src={`https://img.pokemondb.net/sprites/sword-shield/icon/${common.spriteNameTranslater(this.props.member[0], this.props.member[1])}.png`} />);
    }
    else {
      return (null)
    }
  }
}

class SavedTeam extends Component {


  render() {
    var source = "/gameIcons/cover-" + this.props.game + ".png";
    return (
      <div className="team_box">
        <img onClick={() => this.props.loadTeamPlanner(this.props.gen, this.props.game, this.props.team, this.props.teamID)} className="gameCoverImage" src={"/gameIcons/cover-" + this.props.game + ".png"} />
        <div onClick={() => this.props.loadTeamPlanner(this.props.gen, this.props.game, this.props.team, this.props.teamID)}>
          <div>
            <SavedTeamMember member={this.props.team[0]} />
            <SavedTeamMember member={this.props.team[1]} />
            <SavedTeamMember member={this.props.team[2]} />
          </div>
          <div>
            <SavedTeamMember member={this.props.team[3]} />
            <SavedTeamMember member={this.props.team[4]} />
            <SavedTeamMember member={this.props.team[5]} />
          </div>
        </div>
        <div title="Delete Team" className="deleteTeam" onClick={() => this.props.deleteTeam(this.props.teamID)}>
          X
        </div>
      </div>
    );
  }
}

class LoadTeams extends Component {
  render() {
    if (typeof (Storage) !== "undefined") {
      var data = JSON.parse(localStorage.data)
      if (data.teams.length > 0) {
        return (
          <div className="teamSelector">
            {data.teams.map(team => (
              <SavedTeam teamID={team.teamID} gen={team.gen} game={team.game} team={team.team} loadTeamPlanner={this.props.loadTeamPlanner} deleteTeam={this.props.deleteTeam} />
            ))}
          </div>
        );
      } else {
        return (
          <div className="emptyTeam">
            <img src="https://img.pokemondb.net/sprites/black-white/normal/garbodor.png" alt="Garbodor" />
            <div>You have no teams! Select a game above to get started!</div>
          </div>
        );
      }
    } else {
      return (<div>This browser does not support team saving...</div>);
    }
  }
}


class Home extends Component {


  deleteTeam = (teamID) => {
    var data = JSON.parse(localStorage.data)
    for (var team = 0; team < data.teams.length; team++) {
      if (data.teams[team].teamID == teamID) {
        data.teams.splice(team, 1);
      }
    }
    localStorage.data = JSON.stringify(data)
    this.setState({});
  }

  render() {

    return (
      //Home Screen
      <div>
        <div className="menuSection">
          <BrowserView>
          <GameList loadTeamPlanner={this.props.loadTeamPlanner} browser={true} />
          </BrowserView>
          <MobileView>
          <GameList loadTeamPlanner={this.props.loadTeamPlanner} />
          </MobileView>
          <div style={{ "border-bottom": "solid black 3px", "margin-top": "15px" }} browser={false} />
        </div>
        <div className="menuSection">
          <LoadTeams loadTeamPlanner={this.props.loadTeamPlanner} deleteTeam={this.deleteTeam} />
        </div>
      </div>
    );
  }
}

export default Home
